<template>
  <div class="page inPage tagPage">
    <div class="position">
      <i class="lee-icon lee-icon-arrow-left" @click="back"></i>
      <div class="title">Tag</div>
    </div>
    <div class="pageBody">
      <lee-cell-group title="基础用法" inset>
        <lee-cell title="default 类型">
          <template #footer>
            <lee-tag type="default">标签</lee-tag>
          </template>
        </lee-cell>
        <lee-cell title="primary 类型">
          <template #footer>
            <lee-tag type="primary">标签</lee-tag>
          </template>
        </lee-cell>
        <lee-cell title="success 类型">
          <template #footer>
            <lee-tag type="success">标签</lee-tag>
          </template>
        </lee-cell>
        <lee-cell title="danger 类型">
          <template #footer>
            <lee-tag type="danger">标签</lee-tag>
          </template>
        </lee-cell>
        <lee-cell title="warning 类型">
          <template #footer>
            <lee-tag type="warning">标签</lee-tag>
          </template>
        </lee-cell>
      </lee-cell-group>
      <lee-cell-group title="样式风格" inset>
        <lee-cell title="空心样式">
          <template #footer>
            <lee-tag type="primary" plain>标签</lee-tag>
          </template>
        </lee-cell>
        <lee-cell title="圆角样式">
          <template #footer>
            <lee-tag type="primary" round>标签</lee-tag>
          </template>
        </lee-cell>
        <lee-cell title="标记样式">
          <template #footer>
            <lee-tag type="primary" mark>标签</lee-tag>
          </template>
        </lee-cell>
        <lee-cell title="可关闭标签">
          <template #footer>
            <lee-tag type="primary" :show="show" close-able @close="close">标签</lee-tag>
          </template>
        </lee-cell>
      </lee-cell-group>
      <lee-cell-group title="标签大小" inset>
        <lee-cell title="小号标签">
          <template #footer>
            <lee-tag type="primary">标签</lee-tag>
          </template>
        </lee-cell>
        <lee-cell title="中号标签">
          <template #footer>
            <lee-tag type="primary" size="medium">标签</lee-tag>
          </template>
        </lee-cell>
        <lee-cell title="大号标签">
          <template #footer>
            <lee-tag type="primary" size="large">标签</lee-tag>
          </template>
        </lee-cell>
      </lee-cell-group>
      <lee-cell-group title="自定义颜色" inset>
        <lee-cell title="背景颜色">
          <template #footer>
            <lee-tag color="#7232dd" text-color="#fff">标签</lee-tag>
          </template>
        </lee-cell>
        <lee-cell title="文字颜色">
          <template #footer>
            <lee-tag color="#ffe1e1" text-color="#ad0000">标签</lee-tag>
          </template>
        </lee-cell>
        <lee-cell title="空心颜色">
          <template #footer>
            <lee-tag color="#fc0" plain>标签</lee-tag>
          </template>
        </lee-cell>
      </lee-cell-group>
    </div>
    <div class="pageFooter"><img src="@/assets/logo.png"></div>
  </div>
</template>
<script setup>
import {useRouter} from 'vue-router'
import {ref} from 'vue'

const router = useRouter()
const back = () => {
    router.push('/')
}
const show = ref(true)
const close = () => {
    show.value = false;
};

</script>
<style>

</style>
